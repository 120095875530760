import { Component } from '@angular/core';

@Component({
    selector: 'app-shop',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    standalone: false
})
export class ProductComponent {

    constructor () { }


}
