import { Component, OnInit, OnDestroy, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {
    ViewProduct,
    ViewProductColours,
    ColourStockRef,
    StockrefPrice,
    CartItem } from '../../_models/index';
import { PageService, AuthenticationService, SolentExpressService } from '../../_services/index';
import { Lightbox } from 'ngx-lightbox';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import * as fromRoot from '../../_ngrx/index';
import * as myActions from '../../_ngrx/actions';
import {Store} from '@ngrx/store';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WindowRef } from './../../_services/windowref';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ShareButtons } from 'ngx-sharebuttons/buttons';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
    standalone: false
})
export class ProductDetailComponent implements OnInit, OnDestroy {
    faFile = faFile;
    sampleRequest = false;
    product: ViewProduct = null;
    relatedProducts: ViewProduct[];
    currentURL: string =  null;
    expressAvailable = false;
    expressCustomer = false;
    basket: CartItem[];
    orderLines: number;
    basket$: Observable<CartItem[]> = this.store.select(fromRoot.selectBasket);
    lines$: Observable<number> = this.store.select(fromRoot.selectOrderLines);
    selectedImage: ViewProductColours;
    expressReferences: ColourStockRef[] = [];
    selectedStockref: ColourStockRef[];
    stockrefPriceQ: StockrefPrice;
    purchaseForm = new UntypedFormGroup({
        stockref: new UntypedFormControl(null, [Validators.required]),
        qty: new UntypedFormControl(null, Validators.required)
    });
    private albums = [];
    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('continueShoppingModal', { static: true }) private continueShoppingModal;
    @ViewChild('addToCartModal', { static: true }) private addToCartModal;
    constructor (
        private modalService: NgbModal,
        private store: Store<fromRoot.State>,
        private route: ActivatedRoute,
        private router: Router,
        private expressService: SolentExpressService,
        private solentProductService: PageService,
        private title: Title,
        private lightbox: Lightbox,
        private fb: UntypedFormBuilder,
        private authService: AuthenticationService,
        private winRef: WindowRef,
        @Inject(PLATFORM_ID) private platformId: any,) {
        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            const productSlug = params.slug;
            this.solentProductService.getOneProduct(productSlug).pipe(takeUntil(this.ngUnsubscribe)).subscribe(product => {
                this.product = product;  // all products
                this.modalService.dismissAll();
                this.buildLightbox(product.colours);
                this.buildShopItems(product.colours);
                this.getRelatedProducts(product.slug);
                this.title.setTitle('Solent Wholesale | ' + this.product.name);
            });
        });
    }

    getRelatedProducts(slug) {

        this.solentProductService.getRelatedProducts(slug)
            .subscribe(products => this.relatedProducts = products);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    ngOnInit() {
        this.basket$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(basket => this.orderBasket(basket));
        this.lines$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(lines => this.orderLines = lines);
        this.purchaseForm.reset();
        if (isPlatformBrowser(this.platformId)) {
            // Build current URL for share patterns
            this.currentURL = this.winRef.nativeWindow.location.origin + this.router.url;
        }
        this.purchaseForm.get('stockref').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(stockrefID => this.selectStockref(stockrefID));
        this.expressCustomerTest();
    }

    orderBasket(basket: CartItem[]) {
        this.basket = JSON.parse(JSON.stringify(basket));
        this.basket.sort((a, b) => a.stockref.localeCompare(b.stockref));
    }

    requestSample() {
        this.sampleRequest = true;
        const productName = this.product.name;
        console.log(productName);
        this.expressService.sampleRequest(this.authService.returnCustomerNo(), productName)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(x => x);
    }

    expressCustomerTest() {
        this.expressCustomer = this.authService.localTokenTest();
    }

    selectStockref(stockrefID) {
        // Add in null test for when we reset the form
        if (stockrefID !== null) {
            this.selectedStockref = this.expressReferences.filter(x => x.id === stockrefID);
            // Now we need to query the price of this in realitex
            this.solentProductService.priceQuery(this.selectedStockref[0].stockref, null, null, null)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(priceQuery => {
                    this.stockrefPriceQ = priceQuery;  // all product
                });
        }
    }

    calculateSqmPrice(packPrice, packSize) {
        const priceCalc: number = packPrice / packSize;
        return priceCalc.toFixed(2);
    }

    addToCart() {
        this.modalService.dismissAll();
        const newCartItem: CartItem = {
            lineno: this.orderLines + 1,
            stockref: this.selectedStockref[0].stockref,
            qtyLength: this.purchaseForm.get('qty').value,
            qtyWidth: this.selectedStockref[0].width,
            unitPrice: this.stockrefPriceQ.price,
            productID: this.product.id,
            productName: this.product.name,
            productSlug: this.product.slug,
            packSize: this.selectedStockref[0].packSize,
            palletLength: this.selectedStockref[0].palletLength,
            colourName: this.stockrefPriceQ.stockrefName,
            colourID: this.selectedStockref[0].imageID,
            colourAWSURL: this.selectedStockref[0].awsColourURL,
            smallItem: this.selectedStockref[0].smallItem,
        };
        // check width
        if (newCartItem.qtyWidth <= 1) {
            // check if it already exists in state:
            const index = this.basket.findIndex(item => item.stockref === newCartItem.stockref);
            if (index !== -1) {
                // Stockref exists in state all ready
                const currentItem = this.basket.filter(x => x.stockref === newCartItem.stockref);
                const item: CartItem = currentItem[0];
                const currentQty = newCartItem.qtyLength;
                // update new quantity
                newCartItem.qtyLength = Number(currentItem[0].qtyLength) + Number(currentQty);
                // remove old instance
                this.store.dispatch(myActions.removeFromCart ({cartItem: item}));
            };
        }
        // First we need to add the item to the ngrx store
        this.store.dispatch(myActions.addToCart ({cartItem: newCartItem}));

        // Add item to cart in the database, placeholder

        this.purchaseForm.reset();
        this.selectedStockref = null;
        /* Open modal asking if user wants to continue shopping, or
           go to the checkout
        */
        this.modalRefService = this.modalService.open(this.continueShoppingModal, {centered: true});
    }

    buildShopItems(images: ViewProductColours[]) {
        this.expressReferences = [];
        for (const image of images) {
            for (const stockref of image.stockrefs) {
                // If we are here, we know this product is setup for
                // solent express
                if (stockref.width <= 2) {
                    // only show products 2m and under for express
                    this.expressAvailable = true;
                    this.expressReferences.push(stockref);
                }
            }
        }
        if (this.expressReferences.length === 1) {
            this.selectedStockref = this.expressReferences;
            this.purchaseForm.controls.stockref.setValue(this.selectedStockref[0].id);
        }
    }

    buildLightbox(images: ViewProductColours[]) {
        for (const image of images) {
            const album = {
                src: image.awsURL,
                caption: image.name,
                thumb: image.awsURL
            };
            this.albums.push(album);
        }
    }

    testSTR(image: ViewProductColours) {
        if (image.name === null) {
            return image.origFilename.substr(image.origFilename.length - 10);
        }
        return image.name;
    }

    testExpress(image: ViewProductColours) {
        for (const stockreftest of image.stockrefs) {
            if (stockreftest.width <= 2) {
                return true;
            }
        }
        return false;
    }

    openCartModal(image: ViewProductColours) {
        this.purchaseForm.reset();
        this.selectedImage = image;
        if (image.stockrefs.length === 1) {
            this.purchaseForm.controls.stockref.setValue(image.stockrefs[0].id);
        }
        this.modalRefService = this.modalService.open(this.addToCartModal, {centered: true});
    }

    open(index: number): void {
        // open lightbox
        this.lightbox.open(this.albums, index);
    }

    close(): void {
        // close lightbox programmatically
        this.lightbox.close();
    }

    imageCheck(image) {
        if (image === null ) {
            return 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/no_photo.jpg';
        } else {
            return image;
        }
    }

}
