import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ProductCategory, ViewProduct } from '../../_models/index';
import { PageService, } from '../../_services/index';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-product-sectors',
    templateUrl: './product-sector-results.component.html',
    styleUrls: ['./product-sector-results.component.scss'],
    standalone: false
})
export class ProductSectorResultsComponent implements OnInit, OnDestroy {
    public categories: ProductCategory[] = [];
    public productFeature: ViewProduct[] = null;
    public loading = true;
    public sectorName: string = null;
    solentProducts: ViewProduct[];
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private title: Title,
        private route: ActivatedRoute,
        private solentProductService: PageService,
        private pageService: PageService) {
            this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
                const sectorID = params.sector;
                this.getProducts(sectorID);
            });
         }


    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        this.title.setTitle('Solent Wholesale | Product Sectors');
        this.fetchCateogires();
        this.getFeatureProduct();
    }

    fetchCateogires () {
        this.pageService.getCategories(false).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.categories = x);
    }

    getProducts(sectorID) {
        this.loading = true;
        this.solentProductService.getProductsBySector(sectorID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(products=> {
                this.loading = false;
                this.sectorName = products.sector;
                this.solentProducts = products.products;  // all products
            });
    }

    getFeatureProduct() {
        // This function populates with one product to feature
        this.pageService.getFeatureProducts(3).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(products => this.productFeature = products);
    }

    imageCheck(image) {
        if (image === null ) {
            return 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/no_photo.jpg';
        } else {
            return image;
        }
    }


}
