import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ProductCategory, ViewProduct } from '../../_models/index';
import { PageService } from '../../_services/index';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-payments',
    templateUrl: './product-categories.component.html',
    styleUrls: ['./product-categories.component.scss'],
    standalone: false
})
export class ProductCategoriesComponent implements OnInit, OnDestroy {
    public categories: ProductCategory[] = [];
    public productFeature: ViewProduct[] = null;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private title: Title,
        private pageService: PageService) { }


    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        this.title.setTitle('Solent Wholesale | Product Categories');
        this.fetchCateogires();
        this.getFeatureProduct();
    }

    fetchCateogires () {
        this.pageService.getCategories(false).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.categories = x);
    }

    getFeatureProduct() {
        // This function populates with one product to feature
        this.pageService.getFeatureProducts(3).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(products => this.productFeature = products);
    }


}
