<app-header></app-header>
<!-- breadcrumb section start -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Sectors</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a class="white" href="javascript:void()">List Sectors</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!--breadcrumb section end -->
<!-- section start -->


<section class="agency blog blog-sec blog-sidebar blog-split sider">
    <div class="container">
        <div class="row pl-0 pr-0">
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-12 blog-sec blog-list">
                        <div class="blog-agency">
                            <div class="row blog-contain pl-0 pr-0">
                                <div class="col-xl-4 col-lg-5 col-md-6 p-0" [ngClass]="1%2==0?'order-md-2':''">
                                    <div class=" text-center">
                                        <img src="https://swc-website-categories.s3.eu-west-1.amazonaws.com/domestic.jpg" alt="" class="img-fluid blog-img">
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-7 col-md-6 p-0">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <h5 class="blog-head font-600">Domestic</h5>
                                                <p class="para2"></p>
                                                <p>Our core offering, we have over 100 ranges of stocked products suitable for the residential 
                                                    and domestic market across a number of categories: Carpets, Vinyls, LVT & Laminate.
                                                   We have deep stocks across all of these categories and a variety of sampling to suit all customers.</p>
                                                <div class="btn-bottom m-t-20">
                                                    <a [routerLink]="['/products/sector/1']" [queryParams]="{category: 'domestic'}"
                                                        class="btn btn-default primary-btn info">Show Products</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 blog-sec blog-list">
                        <div class="blog-agency">
                            <div class="row blog-contain pl-0 pr-0">
                                <div class="col-xl-4 col-lg-5 col-md-6 p-0" [ngClass]="2%2==0?'order-md-2':''">
                                    <div class=" text-center">
                                        <img src="https://swc-website-categories.s3.eu-west-1.amazonaws.com/luxuryDomestic.jpg" alt="" class="img-fluid blog-img">
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-7 col-md-6 p-0">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <h5 class="blog-head font-600">Luxury Domestic</h5>
                                                <p class="para2"></p>
                                                <p>Looking for something extra special, different from a standard carpet or a hard floor to make the
                                                    ultimate impression? Check out our range of high end and alternative floor coverings.
                                                </p>
                                                <div class="btn-bottom m-t-20">
                                                    <a [routerLink]="['/products/sector/2']" [queryParams]="{category: 'luxury-domestic'}"
                                                        class="btn btn-default primary-btn info">Show Products</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 blog-sec blog-list">
                        <div class="blog-agency">
                            <div class="row blog-contain pl-0 pr-0">
                                <div class="col-xl-4 col-lg-5 col-md-6 p-0" [ngClass]="1%2==0?'order-md-2':''">
                                    <div class=" text-center">
                                        <img src="https://swc-website-categories.s3.eu-west-1.amazonaws.com/commerical.jpg" alt="" class="img-fluid blog-img">
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-7 col-md-6 p-0">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <h5 class="blog-head font-600">Commercial</h5>
                                                <p class="para2"></p>
                                                <p>Office spaces, healthcare, retail, hospitality or other commercial flooring requirements? We have you covered! Safety flooring,
                                                     carpet tiles, commercial carpets, commercial LVT, axminsters, marmoleum or flotex, we will have the product to suit your requirements.
                                                </p>
                                                <div class="btn-bottom m-t-20">
                                                    <a [routerLink]="['/products/sector/3']" [queryParams]="{category: 'commercial'}"
                                                        class="btn btn-default primary-btn info">Show Products</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 blog-sec blog-list">
                        <div class="blog-agency">
                            <div class="row blog-contain pl-0 pr-0">
                                <div class="col-xl-4 col-lg-5 col-md-6 p-0" [ngClass]="2%2==0?'order-md-2':''">
                                    <div class=" text-center">
                                        <img src="https://swc-website-categories.s3.eu-west-1.amazonaws.com/LUXCOM.jpg" alt="" class="img-fluid blog-img">
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-7 col-md-6 p-0">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <h5 class="blog-head font-600">Luxury Commercial</h5>
                                                <p class="para2"></p>
                                                <p>Hotels, boutique retail environments or high end office fit out? If you need a commercial product
                                                     for those high end interiors, we have you covered.
                                                </p>
                                                <div class="btn-bottom m-t-20">
                                                    <a [routerLink]="['/products/sector/4']" [queryParams]="{category: 'luxury-commercial'}"
                                                        class="btn btn-default primary-btn info">Show Products</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 blog-sec blog-list">
                        <div class="blog-agency">
                            <div class="row blog-contain pl-0 pr-0">
                                <div class="col-xl-4 col-lg-5 col-md-6 p-0" [ngClass]="1%2==0?'order-md-2':''">
                                    <div class=" text-center">
                                        <img src="https://swc-website-categories.s3.eu-west-1.amazonaws.com/ha.jpg" alt="" class="img-fluid blog-img">
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-7 col-md-6 p-0">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <h5 class="blog-head font-600">Housing Association</h5>
                                                <p class="para2"></p>
                                                <p>working with housing associations or local authorities?
                                                     We have some fantastic value engineered products ideally suited to this sector.
                                                </p>
                                                <div class="btn-bottom m-t-20">
                                                    <a [routerLink]="['/products/sector/5']" [queryParams]="{category: 'housing-association'}"
                                                        class="btn btn-default primary-btn info">Show Products</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 blog-sec blog-list">
                        <div class="blog-agency">
                            <div class="row blog-contain pl-0 pr-0">
                                <div class="col-xl-4 col-lg-5 col-md-6 p-0" [ngClass]="2%2==0?'order-md-2':''">
                                    <div class=" text-center">
                                        <img src="https://swc-website-categories.s3.eu-west-1.amazonaws.com/marine.jpg" alt="" class="img-fluid blog-img">
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-7 col-md-6 p-0">
                                    <div class="img-container center-content">
                                        <div class="center-content">
                                            <div class="blog-info">
                                                <h5 class="blog-head font-600">Marine</h5>
                                                <p class="para2"></p>
                                                <p>Yachts and pleasure boat carpeting is available off the shelf here at Solent.
                                                     See our hand picked, tried and tested products perfectly suited to this environment.
                                                </p>
                                                <div class="btn-bottom m-t-20">
                                                    <a [routerLink]="['/products/sector/6']" [queryParams]="{category: 'marine'}"
                                                        class="btn btn-default primary-btn info">Show Products</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-lg-3">
                <div class="blog-side">
                     <div>
                         <h5 class="blog-title">Our Products</h5>
                         <div class="sidebar-container borders">
                             Please find below the categories of products that we sell, over 90% of the products
                             available on our website are in stock at our Chichester warehouse. We do also supply
                             goods to special order, in these instances they will be marked as "To Order" when
                             viewing the product.
                         </div>
                     </div>
                     <div>
                         <h5 class="blog-title">Solent Selection</h5>
                         <div class="sidebar-container">
                             Solent Selection is our own brand of exclusive ranges<br />
                             We now offer multiple carpet ranges under our own branding, across multiple widths and backings this offers over 
                             130 unique colour options, if you include multiple widths and backings and pile weight this offering expands to 
                             over 200 unique options, truly something for all customers.
                             <br /><br />
                             <a [routerLink]="['/page/solent-selection']" class="btn btn-default primary-btn info">Solent Selection</a><br /><br />
                         </div>
 
                         
                         <div *ngIf="productFeature?.length" class="sidebar-container">
                             <h5 class="blog-title">Featured Products</h5>
                             <div *ngFor="let product of productFeature;" class="">
                                 <div class="center-content">
                                 <a [routerLink]="['/products/view', product.slug]">
                                 <img src="{{product.mainImageAWSURL}}" alt=""
                                      class="img-fluid sidebarimage">
                                 </a>
                                 <h4 class="post-head grey">{{product.name}}</h4>
                                     <h6 class="date grey">{{product.briefDescription}}</h6>
                                 </div>
                                 <br />
                             </div>
                         </div>
                     </div>
 
                     <div>
 
 
 
                     </div>
                 </div>
             </div>
        </div>
    </div>
</section>



<!-- Section ends -->
<app-footer></app-footer>