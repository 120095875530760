import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProductResultsComponent } from './product-results/product-results.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductCategoriesComponent } from './product-categories/product-categories.component';
import { ProductSectorsComponent } from './product-sectors/product-sectors.component';
import { ProductSectorResultsComponent } from './product-sector-results/product-sector-results.component';

const routes: Routes = [
    {
        path: 'list/:category',
        component: ProductResultsComponent,
        data: {
            title: 'List results of a product search',
            breadcrumb: 'Results for QUERY'
        }
    },
    {
        path: 'view/:slug',
        component: ProductDetailComponent,
        data: {
            title: 'Product Information',
            breadcrumb: 'Product Information'
        }
    },
    {
        path: 'categories',
        component: ProductCategoriesComponent,
        data: {
            title: 'Product Categories',
            breadcrumb: 'Product Categories'
        }
    },
    {
        path: 'sectors',
        component: ProductSectorsComponent,
        data: {
            title: 'Product Sectors',
            breadcrumb: 'Product Sectors'
        }
    },
    {
        path: 'sector/:sector',
        component: ProductSectorResultsComponent,
        data: {
            title: 'List results of a category search',
            breadcrumb: 'Results for QUERY'
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProductRoutingModule {

}
