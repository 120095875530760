import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Products, ColorFilter } from '../../shared/model/product.model';
import { ProductsService } from '../../shared/service/product.service';
import { Title } from '@angular/platform-browser';
import { ViewProduct } from '../../_models/index';
import { PageService } from '../../_services/index';
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-product-results',
    templateUrl: './product-results.component.html',
    styleUrls: ['./product-results.component.scss'],
    standalone: false
})
export class ProductResultsComponent implements OnInit, OnDestroy, AfterViewInit {
    categoryID = null;
    solentProducts: ViewProduct[];
    filterProducts: ViewProduct[];
    productsTest = false;
    selectedPage = 1;
    pages: number[];
    totalProducts: number;
    faCircle = faCircle;
    faCheckCircle = faCheckCircle;

    allColours = [];
    chosenColours = [];
    selectedColourTypeIDs = [];
    allColourTypeIDs = [];

    selectedManufacturerIDs = [];
    allManufacturerIDs = [];
    chosenManufacturers = [];

    public active = false;
    public loading = true;
    public products: Products[] = [];
    public items: Products[] = [];
    public allItems: Products[] = [];
    public colorFilters: ColorFilter[] = [];
    public manufacturers = [];
    public manFilters = [];
    public colors: any[] = [];
    public sortByOrder = '';   // sorting
    public animation: any;   // Animation
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private solentProductService: PageService,
        public productsService: ProductsService,
        private title: Title) {
        this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            const categorys = params.category;
            this.getProducts(categorys);
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        this.title.setTitle('Solent Wholesale | List Products');
    }

    ngAfterViewInit() {
        setTimeout( () => { this.loading = false; }, 5000);
    }

    navToPage(page) {
        this.selectedPage = page;
        this.getProducts(this.categoryID);
    }

    activePagCheck(page) {
        if (page === this.selectedPage) {
            return true;
        }
        return false;
    }

    getProducts(categoryID) {
        this.loading = true;
        this.categoryID = categoryID;
        this.solentProductService.getProducts(categoryID, this.selectedPage)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(productResponse => {
                this.solentProducts = productResponse.products;  // all products
                this.filterProducts = productResponse.products;  // all products
                this.pages = productResponse.pages;
                this.totalProducts = productResponse.total;
                this.loading = false;
                this.productsTest = true;
                this.products = productResponse.products.slice(0, 8);
                this.getTags(productResponse.products);
                this.getColors(productResponse.products);
            });
    }


    // Get current product tags
    public getTags (products: ViewProduct[]) {
        const manArray = [];
        for (const product of products) {
            // Add unique manufacturers to list
            const manObject = {id: product.manufacturerID, name: product.manufacturerName};

            if (!manArray.some((item) => item.id === product.manufacturerID)) {
                manArray.push(manObject);
                this.allManufacturerIDs.push(product.manufacturerID);
            }
        }
        this.manufacturers = manArray;
    }

    // Get current product colors
    public getColors (products: ViewProduct[]) {
        const colourTypeArray = [];
        for (const product of products) {
            // Add unique manufacturers to list
            for (const colour of product.colours) {
                if (colour.colourTypeID !== null) {
                    const colourObject = {
                        id: colour.colourTypeID,
                        colourTypeName: colour.colourTypeName,
                        colourTypeHexValue: colour.colourTypeHexValue
                    };
                    if (!colourTypeArray.some((item) => item.id === colour.colourTypeID)) {
                        colourTypeArray.push(colourObject);
                        this.allColourTypeIDs.push(colour.colourTypeID);
                    }

                }
            }
        }
        this.allColours = colourTypeArray;
    }

    selectColour(colour) {
        const colourTypeTest = this.chosenColours.indexOf(colour) !== -1;
        if ( colourTypeTest === false ) {
            // Item does not exisit, add it to array
            // stop user adding it twice
            this.chosenColours.push(colour);
            // Now we need to filter solentProducts, to only show those
            // that contain the following colour

        } else {
            this.chosenColours = this.chosenColours.filter(item => item !== colour);
        }
        this.filterProdcutsByColour(colour.id);
    }

    selectMan(man) {

        const manufacturerTest = this.chosenManufacturers.indexOf(man) !== -1;
        if ( manufacturerTest === false ) {
            // Item does not exisit, add it to array
            // stop user adding it twice
            this.chosenManufacturers.push(man);
            // Now we need to filter solentProducts, to only show those
            // that contain the following colour
        } else {
            this.chosenManufacturers = this.chosenManufacturers.filter(item => item !== man);
        }
        this.filterProdcutsByManufacturer(man.id);

    }

    filterProdcutsByManufacturer(manufacturerID) {
        const manufacturerTest = this.selectedManufacturerIDs.indexOf(manufacturerID) !== -1;
        if ( manufacturerTest === false ) {
            this.selectedManufacturerIDs.push(manufacturerID);
        } else {
            this.selectedManufacturerIDs = this.selectedManufacturerIDs.filter(item => item !== manufacturerID);
        }

        this.filterProdcutsCombined();
    }

    checkedMan(manufacturerID) {
        // return true if manufacturerID is in this.selectedManufacturerIDs
        const manufacturerTest = this.selectedManufacturerIDs.indexOf(manufacturerID) !== -1;
        return manufacturerTest;
    }

    checkedColour(colourID) {
        // return true if manufacturerID is in this.selectedManufacturerIDs
        const colourTypeTest = this.selectedColourTypeIDs.indexOf(colourID) !== -1;
        return colourTypeTest;
    }


    filterProdcutsByColour(newColourID) {
        const colourTypeTest = this.selectedColourTypeIDs.indexOf(newColourID) !== -1;
        if ( colourTypeTest === false ) {
            this.selectedColourTypeIDs.push(newColourID);
        } else {
            this.selectedColourTypeIDs = this.selectedColourTypeIDs.filter(item => item !== newColourID);
        }

        this.filterProdcutsCombined();
    }

    public filterProdcutsCombined() {
        let customManFilter = [];
        if (this.selectedManufacturerIDs.length === 0) {
            customManFilter = this.allManufacturerIDs;
        } else {
            customManFilter = this.selectedManufacturerIDs;
        }
        let customColourFilter = [];
        if (this.selectedColourTypeIDs.length === 0) {
            customColourFilter = this.allColourTypeIDs;
        } else {
            customColourFilter = this.selectedColourTypeIDs;
        }

        const newProducts = this.solentProducts
            .filter(
                product => product.colours.some(
                    colours => customColourFilter.indexOf(colours.colourTypeID) > -1) &&
                  customManFilter.indexOf(product.manufacturerID) > -1);
        this.filterProducts = newProducts;
    }



    toggleFilter () {
        this.productsService.filterBar = !this.productsService.filterBar;
    }

    closeOverlay () {
        // Close sidebar on click on outside the sidebar
        this.productsService.filterBar = false;
    }

    imageCheck(image) {
        if (image === null ) {
            return 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/no_photo.jpg';
        } else {
            return image;
        }
    }

}
