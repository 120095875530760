import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Third party imports
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareButtonDirective } from 'ngx-sharebuttons';
import { ShareButtons } from 'ngx-sharebuttons/buttons';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxLoadingModule } from 'ngx-loading';
// Local imports
import { WindowRef } from './../_services/windowref';
import { DigitOnlyDirective } from './digit-only.directive';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutsModule } from '../layouts/layouts.module';
import { ProductRoutingModule } from './product-routing.module';
import { ProductComponent } from './product.component';
import { SafePipe } from './transform.component';
import { ProductResultsComponent } from './product-results/product-results.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductCategoriesComponent } from './product-categories/product-categories.component';
import { ProductSectorsComponent } from './product-sectors/product-sectors.component';
import { ProductSectorResultsComponent } from './product-sector-results/product-sector-results.component';
@NgModule({
    declarations: [
        ProductComponent,
        ProductResultsComponent,
        ProductDetailComponent,
        ProductCategoriesComponent,
        ProductSectorsComponent,
        ProductSectorResultsComponent,
        SafePipe,
        DigitOnlyDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProductRoutingModule,
        SharedModule,
        LayoutsModule,
        NgbModule,
        CarouselModule,
        FontAwesomeModule,
        ShareButtonDirective,
        ShareButtons,
        LightboxModule,
        NgxLoadingModule.forRoot({})
    ],
    providers: [
        WindowRef,
        provideShareButtonsOptions(
            shareIcons()
        )
    ]
})
export class ProductModule { }
