<app-header></app-header>
<!-- breadcrumb section start -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Products in sector "{{ sectorName }}"</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a class="white" href="javascript:void()">Search Results</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<!-- section start -->
<section class="section-b-space">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <div class="collection-content col-sm-12">
          <div class="page-main-content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="collection-product-wrapper">
                    <!-- Filter Block Products -->
                    <div class="product-top-filter">
                      <div class="container-fluid p-0">
                        <div class="row">
                          <div class="col-xl-12">
                            <div class="filter-main-btn">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="product-filter-content">

                              <!--
                              <div class="product-page-filter">
                                <select (change)="onChangeSorting($event.target.value)">
                                  <option value="asc">Sorting items</option>
                                  <option value="asc">Ascending Order</option>
                                  <option value="desc">Descending Order</option>
                                  <option value="a-z">Alphabetically, A-Z</option>
                                  <option value="z-a">Alphabetically, Z-A</option>
                                  <option value="low">Price, low to high</option>
                                  <option value="high">Price, high to low</option>
                                </select>
                              </div>
                            -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Block Products -->
                    <!-- Display Products -->
                    <div class="product-wrapper-grid" [class.list-view]="false" >
                      <div class="container-fluid">
                        <div class="row">
                          <div [class.col-xl-3]="true" 
                               [class.col-md-6]="true" 
                               class="col-grid-box" 
                               *ngFor="let product of solentProducts">
                            <div class="product-box">
                              
                                <div class="img-wrapper">
                                    <div class="front">
                                      <a [routerLink]="['/products/view', product.slug]">
                                        <img alt="" class="img-fluid img-product-results" 
                                             routerLinkActive="active" [src]="imageCheck(product.mainImageAWSURL)">
                                      </a>
                                    </div>

                                  </div>
                                  <div class="product-detail">
                                    <a [routerLink]="['/products/view', product.slug]" class="notext">
                                      <h6 class="product-title">{{product.name}}</h6>
                                    </a>
                                    <span *ngIf="product.clearance === true;" class="discon"> * Limited Stock * <br /></span>
                                    <span>                                                                         {{product.briefDescription}} </span> <br />
                                    
                                  </div>


                            </div>


                          </div>


                        </div>
                      </div>



                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        

          <!-- End Collection Sidebar -->
      </div><!-- end row -->
    </div>
  </div>
</section>
<!-- section End -->
<app-footer></app-footer>
